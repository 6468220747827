import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
const items = [
    {
      src: "url(" + require("assets/img/header/banner1.jpg") + ")",
      content: (
        <Container>
          <Row>
            <Col>
              <h2 className="title">PARCELAS DESDE 5.000 M2</h2>
              <p>Ubicadas en un entorno de bosque nativo y praderas</p>
              <br />
              <p>Segunda etapa en ejecución</p>
            </Col>
          </Row>
        </Container>
      ),
      altText: "",
      caption: ""
    },
    {
      src: "url(" + require("assets/img/header/banner2.jpg") + ")",
      content: (
        <Container>
          <Row>
            <Col>
              <h2 className="title">Ubicada en hermoso paisaje que combina bosques, praderas y hermosas vistas</h2>
              <br />
            </Col>
          </Row>
        </Container>
      ),
      altText: "",
      caption: ""
    },
    {
      src: "url(" + require("assets/img/header/banner3.jpg") + ")",
      content: (
        <Container>
          <Row>
            <Col>
              <h2 className="title">Consulte por unidades disponibles y formas de pago</h2>
              <br />
            </Col>
          </Row>
        </Container>
      ),
      altText: "",
      caption: ""
    }
  ];
  function Header() {
    // carousel - header 3
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
      setAnimating(true);
    };
    
    const onExited = () => {
      setAnimating(false);
    };
    const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    };
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    };
    const goToIndex = newIndex => {
      if (animating) return;
      setActiveIndex(newIndex);
    };

    return (
      <>
          <div id="inicio" className="header-3">
            <div className="page-carousel">
              <div className="filter" />
              <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {items.map(item => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.src}
                    >
                      <div
                        className="page-header"
                        style={{ backgroundImage: item.src }}
                      >
                        <div className="filter" />
                        <div className="content-center">{item.content}</div>
                      </div>
                    </CarouselItem>
                  );
                })}
                <a
                  className="left carousel-control carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                > 
                  <i className="nc-icon icon-ctrl-left" />
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="right carousel-control carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <i className="nc-icon icon-ctrl-right" />
                  <span className="sr-only">Next</span>
                </a>
              </Carousel>
            </div>
          </div>
      </>
    );
  }
  
  export default Header;
  