import React from 'react'
import { Container, Row, Col } from 'reactstrap'

function Forma(){
    return(
      <div className="section bg" id="forma">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title white">Formas de pago</h2>
            </Col>
          </Row>
          <Row className="py-5">
            <Col><p className="text-right white">1- Pago Contado</p></Col>
            <Col>
              <div className="icon text-center white">
                <i aria-hidden={true} className="nc-icon x3 icon-handshake" />
              </div>
            </Col>
            <Col><p className="text-left white">2- Pie desde los 9 millones y cuotas fijas desde 12 a 60.</p></Col>
          </Row>
        </Container>
      </div>
    )
}
export default Forma