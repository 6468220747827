import React from 'react'
// import Mapa from './Googlemaps'
import { Container, Row } from 'reactstrap'
function Ubicacion(){
    // const mapsSelector = () => {
    //     if /* if we're on iOS, open in Apple Maps */
    //       ((navigator.platform.indexOf("iPhone") !== -1) || 
    //       (navigator.platform.indexOf("iPad") !== -1) || 
    //       (navigator.platform.indexOf("iPod") !== -1))
    //       window.open("maps://maps.google.com/maps?daddr=-45.930044,-71.852965&amp;ll=");
    //     else /* else use Google */
    //       window.open("https://maps.google.com/maps?daddr=-45.930044,-71.852965&amp;ll=");
    // }
    return(
        <div id="ubicacion" className="section-ap">
            <Container>
                <Row>
                    <div className="embed-responsive-vt">
                        <iframe className="embed-responsive-item-vt" title='masterplan' src="https://www.google.com/maps/d/u/0/embed?mid=1bRu1cHCiZU2t8_sMRAUbClB24nxHU_7s"></iframe>
                    </div>       
                </Row>
            </Container>      
        </div>
    )
}

export default Ubicacion