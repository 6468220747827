import React from 'react'
import Descripcion from './Descripcion'
import Caracteristicas from './Caracteristicas'
import Forma from './Forma'

function Proyecto(){
    return(
        <div>
            <Descripcion/>
            <Caracteristicas />
            <Forma />
        </div>
    )
}
export default Proyecto