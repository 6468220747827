import React, { Component } from 'react'
import axios from 'axios'
import { Form, Col, Row, Input, FormGroup, CardBody, Button } from 'reactstrap'
import { withFirebase } from '../Firebase'

const INITIAL_STATE = {
    textNombre: '',
    textApellido:'',
    textEmail:'',
    textMensaje:'',
    textTelefono:''
}
class FormularioBase extends Component {
    constructor(props){
        super(props)
        this.state={
            loading:false,
            textNombre:'',
            textApellido:'',
            textTelefono:'',
            textEmail:'',
            textMensaje:''
        }
    }
    onChangeText = event => {
        this.setState({ [event.target.name]: event.target.value })
    }
    onEnviarFormulario = (event) => {
        const { textNombre, textApellido, textTelefono, textEmail, textMensaje } = this.state
        const nombreCompleto = textNombre + ' ' + textApellido

        event.preventDefault()
        const message = `
            Nombre: ${nombreCompleto} 
            Email: ${textEmail} 
            Mensaje: ${textMensaje}
            Telefono:${textTelefono}
        `
        this.setState({loading:true})
        const data = {
            to:'contacto@donjaime.cl',
            replyTo: textEmail,
            subject:'Contacto Parcelas Don Jaime',
            text: message
        }
        axios.post('https://us-central1-firemailer.cloudfunctions.net/submitContactoFZ', data)
            .then(response => {
                console.log(response.data)
                this.setState({
                    loading:false, ...INITIAL_STATE
                })
            }).catch(error=> {
                console.log('error al enviar correo', error)
            })
        this.setState({ 
            ...INITIAL_STATE
        })
    }
    render(){
        const { loading, textNombre, textApellido, textTelefono, textEmail, textMensaje} = this.state
        const isInvalid = textNombre === '' || textApellido === '' || textTelefono === '' || textEmail ==='' || textMensaje === ''
        return(
            <Form onSubmit={this.onEnviarFormulario}>
                <CardBody>
                    <Row>
                        <Col md="6">
                            <FormGroup className="label-floating">
                                <label className="control-label">
                                    Nombre
                                </label>
                                <Input
                                    name="textNombre"
                                    value={textNombre}
                                    onChange={this.onChangeText}
                                    placeholder=""
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup className="label-floating">
                                <label className="control-label">
                                    Apellido
                                </label>
                                <Input
                                    name="textApellido"
                                    value={textApellido}
                                    onChange={this.onChangeText}
                                    placeholder=""
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="label-floating">
                                <label className="control-label">
                                    Teléfono
                                </label>
                                <Input
                                    name="textTelefono"
                                    value={textTelefono}
                                    onChange={this.onChangeText}
                                    placeholder=""
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup className="label-floating">
                        <label className="control-label">
                            Email 
                        </label>
                        <Input
                            name="textEmail"
                            value={textEmail}
                            onChange={this.onChangeText}
                            placeholder=""
                            type="email"
                        />
                    </FormGroup>
                    <FormGroup className="label-floating">
                        <label className="control-label">
                            Mensaje
                        </label>
                        <Input
                            id="message"
                            name="textMensaje"
                            value={textMensaje}
                            onChange={this.onChangeText}
                            placeholder=""
                            type="textarea"
                            rows="6"
                        />
                    </FormGroup>
                    <Row>
                        <Col md="6">
                            <Button type ="submit" color="primary" disabled={isInvalid}>
                                {loading && 
                                    <div className="uil-reload-css reload-small mr-1">
                                        <div />
                                    </div>
                                }
                                Enviar
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Form>
        )
    }
}
const Formulario = withFirebase(FormularioBase)
export default Formulario