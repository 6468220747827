import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import LogoBlanco from '../../assets/img/logo.png'
import LogoColor from '../../assets/img/logo-color.png'
import { Collapse, NavbarBrand, Navbar, NavItem, Nav, NavLink, Container, DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem } from "reactstrap";
// core components

function Menu() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [imgLogo, setImgLogo] = React.useState(LogoBlanco)
  React.useEffect(() => {
    
    // initialise
    
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("navbar-perla");
        setImgLogo(LogoBlanco)
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("navbar-transparent");
        setImgLogo(LogoColor)
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      <Navbar className={classnames("fixed-top mb-0 pt-1", navbarColor)} expand="lg" id="navbar-main">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" className="py-0 my-0" to="/#inicio" tag={Link}>
              <img src={imgLogo} className="mt-0" style={{width:'220px',zIndex:10}} alt="logo"/>
            </NavbarBrand>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  PROYECTO
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/#descripcion" tag={Link}>
                    Descripción
                  </DropdownItem>
                  <DropdownItem to="/#caracteristicas" tag={Link}>
                    Características
                  </DropdownItem>
                  <DropdownItem to="/#forma" tag={Link}>
                    Formas de pago
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink to="/#masterplan" tag={Link}>
                  MASTERPLAN 360
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/#video" tag={Link}>
                  VIDEO AÉREO
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/#ubicacion" tag={Link}>
                  UBICACIÓN
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="http://www.tycorp.cl" target="_blank">
                  Alianzas
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/#contacto" tag={Link}>
                  CONTACTO
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Menu;
