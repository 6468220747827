import React from 'react'
import { Container, Row, Col} from 'reactstrap'

function Descripcion(){
    return(
        <div id="descripcion" className="section">
            <Container>
                <Row>
                    <Col>
                        <h2 className="title">El Proyecto</h2>
                        {/* <p className="title-proyecto">Condominio Don Jaime</p> */}
                        <p className="parrafo-proyecto">
                            Ubicado a 20 minutos de los muermos, el proyecto busca la conservación de los espacios naturales 
                            ideales para desarrollar actividades vinculadas a la agroecología. 
                        </p>   
                    </Col>
                </Row>       
            </Container>
        </div>
    )
}
export default Descripcion