import React from 'react'
import { Container } from 'reactstrap'
const Masterplan = () => {
    return(
        <div className="section-ap text-center" id="masterplan">
            <Container>
                <h2 className="title text-center"> Masterplan 360</h2>
            </Container>
            <div className="embed-responsive-vt">
                <div className="box">
                    {/* <div className="transparencia centrado"></div>
                    <p className="texto centrado text-center"> 
                        Haz click en el número de cada lote y verás su precio, superficie y descripción. 
                    </p>  */}
                     <iframe className="embed-responsive-item-vt" title='masterplan' src="https://www.lanube360.com/donjaime2/"></iframe>

                </div>
            </div>
        </div>
    )
}
export default Masterplan