import React from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import Formulario from './Formulario.js'
// import ZonaPropia from '../../assets/img/logo-zona-propia.png'
const Contacto = () => {
  return (
      <div className="section section-contactus cd-section" id="contacto">
        <div
          className="contactus-1 section-gray"
          style={{
            backgroundImage:
              "url(" + require("assets/img/sections/contacto-color.jpg") + ")"
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <Card className="card-contact no-transition">
                  {/* <CardTitle className="text-center" tag="h3">
                    Contactanos
                  </CardTitle> */}
                  <Row>
                    <Col className="ml-auto" md="5">
                      <CardBody>
                        <div className="info info-horizontal py-0 py-md-4">
                          <div className="icon icon-info">
                              <i className="nc-icon icon-phone" />
                          </div>
                          
                          <div className="description">
                            <h4 className="info-title">
                              Contáctanos
                            </h4>
                            <p className="pb-2">Información y Ventas:</p>
                            <p className="nombre-contacto">
                              María Christiansen<br />
                              <a href="tel:+56996897029">+ 56 9 96897029<br/></a> 
                            </p>
                            <p className="nombre-contacto">
                              Paulina Embry<br />
                              <a href="tel:+56967392274">+ 56 9 67392274<br/></a> 
                            </p>
                          </div>
                        </div>
                        <div className="info info-horizontal py-0 py-md-4">
                          <a href="mailto:ventas@condominiodonjaime.cl">
                            <div className="icon icon-danger">
                              <i className="nc-icon icon-mail" />
                            </div>
                          </a>
                          <div className="description">
                            <h4 className="info-title">Escríbenos</h4>
                            <a href="mailto:contacto@donjaime.cl ">
                              <p className="nombre-contacto">
                                contacto@donjaime.cl <br />
                              </p>
                            </a>
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                    <Col className="mr-auto" md="5">
                      <Formulario/>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
  )
}

export default Contacto
