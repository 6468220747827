import React from "react";
import ReactDOM from "react-dom";
import Firebase, { FirebaseContext } from './components/Firebase'

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

import App from './components/App'
ReactDOM.render(
  <FirebaseContext.Provider value = {new Firebase()}>
     <App/>
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
