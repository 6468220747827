import React from 'react'
import { Container, Row, Col } from 'reactstrap'

function Caracteristicas(){
    return(
        <div
            id="caracteristicas"
            className="features-5 section-image"
            style={{
                backgroundImage:
                    "url(" +
                    require("assets/img/sections/caracteristicas-color.jpg") +
                    ")"
            }}
        >
          <Container>
            <Row>
              <div className="ml-auto mr-auto">
                <h2 className="title text-center pt-0 mt-0">
                  Características
                </h2>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-i-lock" />
                  </div>
                  <h4 className="title">Espacios destinados a la conservación</h4>
                  <p>Cuenta con acceso controlado</p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-rowing" />
                  </div>
                  <h4 className="title">Zonas Comunes</h4>
                  <p>Cuenta con áreas comunes y áreas verdes para residentes</p>
                  <p>Quincho para uso de propietarios</p>
                  <p>Propietarios tienen acceso a río maullin con estacionamiento privado ubicado cerca de la parcelación</p>
                </div>
              </Col>
            </Row>
            <Row className="bottom-line">
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-water-sink" />
                  </div>
                  <h4 className="title">Servicios Basicos</h4>
                  <p>Parcelas cuentan con electricidad y agua potable</p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-map-pin-2" />
                  </div>
                  <h4 className="title">Excelente Ubicación</h4>
                  <p>A 5 min. del río Maullín, sector Chelles.</p>
                  <p>A 30 min. de playas: Puerto Godoy, Estaquilla, Hua Huar.</p>
                  <p>A 8 min. de los Muermos, y a 45 min. de Puerto Montt.</p>
                  <p>A 30 min. del aeropuerto el Tepual</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
    )
}
export default Caracteristicas